import React from 'react';
import styled from 'styled-components';

const ContainerStyles = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .img-wrapper {
    width: 200px;
    height: 200px;
    clip-path: circle(100px at center);
    background: var(--green);

    img {
      vertical-align: middle;
    }
  }

  .text-wrapper {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0 4rem;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      margin: 4rem 0 0;
    }
  }
`;

export default function Bio({ image, text }) {
  return (
    <ContainerStyles>
      <div className="img-wrapper">
        <img src={image} alt="headshot" />
      </div>
      <div className="text-wrapper">
        <p>{text}</p>
      </div>
    </ContainerStyles>
  );
}
