import React from 'react';
import styled from 'styled-components';
import 'react-slidedown/lib/slidedown.css';
import RichText from './RichText';

const ContainerStyles = styled.div`
  margin-bottom: 4rem;

  & > *:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 6rem;

    & > *:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }
`;

export default function AboutText({ paragraphs }) {
  return (
    <ContainerStyles>
      {paragraphs.map((paragraph) => (
        <RichText key={paragraph._key} rawText={paragraph} />
      ))}
    </ContainerStyles>
  );
}
