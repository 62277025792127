import React from 'react';
import styled from 'styled-components';

const ContainerStyles = styled.div`
  h3 {
    display: inline-block;
  }

  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
`;

export default function SectionTitle({ title }) {
  const createMarkup = () => ({
    __html: `${title.split(' ')[0]} <br />${title.split(' ')[1]}`,
  });

  return (
    <ContainerStyles className="section-title">
      {title.indexOf(' ') === -1 ? (
        <h3 className="text--strong underline">{title}</h3>
      ) : (
        <h3
          className="text--strong underline"
          dangerouslySetInnerHTML={createMarkup()}
        />
      )}
    </ContainerStyles>
  );
}
