import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: (props) =>
      props.node.style === 'h2' ? (
        <p className="text--size-large">{props.children}</p>
      ) : (
        <p className="text--size-medium">{props.children}</p>
      ),
  },
};

export default function RichText({ rawText }) {
  return <BlockContent blocks={rawText} serializers={serializers} />;
}
