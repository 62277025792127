import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Bio from '../components/Bio';
import Hero from '../components/Hero';
import SectionTitle from '../components/SectionTitle';
import rmitLogo from '../assets/images/rmit-logo.svg';
import youngbloodsLogo from '../assets/images/youngbloods-logo.svg';
import AboutText from '../components/AboutText';
import SwiperContainer from '../components/SwiperContainer';
import FormContainer from '../components/FormContainer';

const SectionStyles = styled.section`
  padding: 8rem 4rem;
  position: relative;

  @media (max-width: 1024px) {
    padding: 4rem 2rem;
  }

  & > div {
    &:first-of-type {
      position: absolute;

      @media (max-width: 768px) {
        position: relative;
        font-size: 1.2rem;
        margin-bottom: 4rem;
      }
    }

    &:last-of-type {
      width: 75%;
      max-width: 1020px;
      margin: 0 auto;

      @media (max-width: 768px) {
        width: auto;
      }
    }
  }

  &#about {
    padding-top: 4rem;
  }

  &.full-width {
    padding: 12rem 4rem;
    margin-top: 8rem;
    background: var(--green);

    @media (max-width: 1024px) {
      padding: 8rem 2rem;
    }

    @media (max-width: 768px) {
      padding: 4rem 2rem;
    }

    p {
      text-align: center;
      font-weight: bold;
      color: var(--black);
      width: 75%;
      margin: 0 auto;
    }

    @media (max-width: 600px) {
      margin-top: 4rem;

      p {
        width: 100%;
        font-size: 2rem;
      }
    }
  }

  &#proudly-supporting {
    .section-title {
      @media (max-width: 600px) {
        margin-bottom: 6rem;
      }

      @media (min-width: 601px) {
        margin-bottom: 8rem;
      }
    }
  }

  .proudly-supporting-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    & > div {
      text-align: center;
      width: 45%;

      img {
        margin-bottom: 6rem;
      }

      @media (max-width: 600px) {
        text-align: left;
        width: 100%;

        &:first-of-type {
          margin-bottom: 6rem;
        }

        img {
          margin-bottom: 2rem;
          width: 12rem;
        }
      }
    }
  }

  .contact-wrapper {
    display: flex;
    justify-content: space-between;

    .social-links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      a {
        font-weight: bold;
        width: fit-content;

        &:last-of-type {
          margin-top: -0.5rem;
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;

      & > div:not(:last-of-type) {
        margin-bottom: 4rem;
      }

      .social-links a:first-of-type {
        margin-bottom: 2rem;
      }
    }
  }

  &#contact {
    @media (max-width: 1024px) {
      padding: 8rem 2rem;
    }

    @media (max-width: 768px) {
      padding: 4rem 2rem;
    }
  }
`;

export default function HomePage({ data }) {
  const [isOverlayShowing, setIsOverlayShowing] = useState(false);

  const updateOverlayState = () => {
    setIsOverlayShowing(!isOverlayShowing);
    const body = document.querySelector('body');
    if (body.classList.contains('overlay-active')) {
      document.body.classList.remove('overlay-active');
    } else {
      document.body.classList.add('overlay-active');
    }
  };

  const { home, settings } = data;
  const services = [
    {
      id: 'agencies',
      content: home.agenciesText,
      buttonText: 'A<br />Talent Brief',
    },
    {
      id: 'talent',
      content: home.talentText,
      buttonText: '<br />Your CV',
    },
  ];
  return (
    <>
      <Helmet>
        <meta name="description" content={settings.description} />
        <html lang="en" />
        <title>{settings.title}</title>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <meta name="description" content={settings.description} />
        <meta property="og:url" content="https://ipopulate.com.au" />
        <meta
          property="og:image"
          content="https://ipopulate.com.au/og-image.svg"
        />
        <meta property="og:title" content={settings.title} key="ogtitle" />
        <meta
          property="og:site_name"
          content={settings.title}
          key="ogsitename"
        />
        <meta
          property="og:description"
          content={settings.description}
          key="ogdesc"
        />
      </Helmet>
      <Hero />
      <SectionStyles id="about" className="about">
        <SectionTitle title="About" />
        <div>
          <AboutText paragraphs={home._rawAboutText} />
          <Bio image={home.bioImage.asset.url} text={home.bioText} />
        </div>
      </SectionStyles>
      <SectionStyles id="services">
        <SectionTitle title="Services" />
        <SwiperContainer
          services={services}
          updateOverlayState={updateOverlayState}
        />
      </SectionStyles>
      <FormContainer
        isOverlayShowing={isOverlayShowing}
        updateOverlayState={updateOverlayState}
      />
      <SectionStyles id="proudly-supporting">
        <SectionTitle title="Proudly Supporting" />
        <div className="proudly-supporting-wrapper">
          <div>
            <img src={rmitLogo} alt="rmit" />
            <p>{home.rmitText}</p>
          </div>
          <div>
            <img src={youngbloodsLogo} alt="youngbloods" />
            <p>{home.youngbloodsText}</p>
          </div>
        </div>
      </SectionStyles>
      <SectionStyles className="full-width">
        <p className="text--size-large">{home.statementText}</p>
      </SectionStyles>
      <SectionStyles id="contact">
        <SectionTitle title="Contact" />
        <div className="contact-wrapper">
          <div>
            <p>{settings.address}</p>
          </div>
          <div>
            <p>
              <a href={`tel:${settings.phone}`}>{settings.phone}</a>
              <br />
              <br />
              <a href={`mailto:${settings.email}`}>{settings.email}</a>
            </p>
          </div>
          <div className="social-links">
            <a
              href={settings.linkedinLink}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              href={settings.twitterLink}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>
      </SectionStyles>
    </>
  );
}

export const query = graphql`
  query {
    home: sanityHomePage {
      _rawAboutText
      bioText
      bioImage {
        asset {
          url
        }
      }
      agenciesText
      talentText
      rmitText
      youngbloodsText
      statementText
    }
    settings: sanitySiteSettings {
      title
      description
      address
      phone
      email
      linkedinLink
      twitterLink
    }
  }
`;
