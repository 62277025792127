import React, { useState } from 'react';
import styled from 'styled-components';

const SuccessStyles = styled.div`
  transition: all 400ms ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

const FormStyles = styled.form`
  border: 2px solid var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8rem;
  padding: 8rem;

  & > fieldset {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 2rem;
  }

  & > div {
    display: grid;
    grid-template-areas:
      'fieldset'
      'fieldset'
      'fieldset'
      'fieldset'
      'submit';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 2rem;
  }

  #fieldset {
    grid-area: fieldset;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 2rem;
    padding-top: 1rem;

    p {
      text-align: right;

      &.placeholder {
        opacity: 0.5;
      }
    }
  }

  #upload {
    position: relative;
    text-align: right;

    label {
      font-weight: bold;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  #textarea-wrapper {
    border: 2px solid var(--white);

    input {
      border: none;
    }
  }

  #submit {
    width: fit-content;
    margin-left: auto;
    font-weight: bold;
  }

  #favoriteFilm {
    display: none;
  }

  @media (max-width: 1023px) {
    grid-gap: 4rem;
    padding: 4rem;
  }

  @media (max-width: 600px) {
    height: 100%;
    border: none;
    grid-gap: 2rem;
    grid-template-columns: 1fr;

    #upload,
    #fieldset p {
      text-align: left;
    }
  }
`;

export default function Form() {
  const [selectedFile, setSelectedFile] = useState('No file chosen');

  const handleSelectedFile = (e) => {
    setSelectedFile(e.target.files[0].name);
  };

  return (
    <>
      <FormStyles name="submissions" method="POST" data-netlify="true">
        {/* hidden fields for netlify */}
        {/* <input type="hidden" name="bot-field" /> */}
        <input type="hidden" name="form-name" value="submissions" />
        <fieldset>
          <input type="text" name="name" placeholder="Name*" required />
          <input type="email" name="email" placeholder="Email*" required />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number*"
            required
          />
          <input type="text" name="company" placeholder="Company" />
          <input type="text" name="position" placeholder="Position" />
        </fieldset>
        <div>
          <fieldset id="fieldset">
            <div id="upload">
              <label htmlFor="file">+ Upload Your CV</label>
              <input
                id="file"
                type="file"
                name="file"
                onChange={(e) => handleSelectedFile(e)}
              />
            </div>
            <p>{selectedFile}</p>
            <div id="textarea-wrapper">
              <input
                id="textarea"
                type="textarea"
                rows="5"
                name="message"
                placeholder="Message"
              />
            </div>
          </fieldset>
          <button id="submit" type="submit">
            Submit
          </button>
        </div>
      </FormStyles>
    </>
  );
}
