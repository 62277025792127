import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import Brandmark from './Brandmark';

const SectionStyles = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 601px) {
    .lb-mobile {
      display: none;
    }
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
  }
`;

export default function Hero() {
  return (
    <SectionStyles>
      <Brandmark />
      <h2 className="text--strong text--size-xlarge">
        World <br className="lb-mobile" />
        Class
        <br />
        <Typewriter
          options={{
            strings: ['Agencies.', 'Talent.'],
            autoStart: true,
            loop: true,
          }}
        />
      </h2>
    </SectionStyles>
  );
}
