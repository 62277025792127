import React from 'react';
import styled from 'styled-components';

const ButtonStyles = styled.button`
  height: 15rem;
  width: 15rem;
  background: var(--green);
  color: var(--black);
  font-weight: bold;
  border-radius: 50%;

  @media (max-width: 768px) {
    height: 10rem;
    width: 10rem;
  }

  span {
    transition: opacity 200ms ease-in;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
`;

export default function ModalTrigger({ updateOverlayState, activeSection }) {
  return (
    <ButtonStyles type="button" onClick={updateOverlayState}>
      <span className={activeSection === 'agencies' ? 'active' : null}>
        Submit A<br />
        Talent Brief
      </span>
      <span className={activeSection === 'talent' ? 'active' : null}>
        Submit
        <br />
        Your CV
      </span>
    </ButtonStyles>
  );
}
