import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ContainerStyles = styled.div`
  width: 33.3333%;
  position: absolute;
  left: 8.3333%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  z-index: -1;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 75%;
  }

  @keyframes flash {
    0% {
      opacity: var(--opacityStart);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: var(--opacityStart);
    }
  }

  & > * {
    aspect-ratio: 1;
    background: var(--green);
    opacity: var(--opacityStart);
    animation-delay: var(animationDelay);
    animation-duration: 1.25s;
    animation-timing-function: linear;

    &:nth-of-type(3) {
      background: #000;
    }

    &:nth-of-type(2),
    &:nth-of-type(6) {
      --opacityStart: 0.8;
    }

    &:nth-of-type(7) {
      --opacityStart: 0.6;
      --animationDelay: 1s;
    }

    &:nth-of-type(4),
    &:nth-of-type(8) {
      --opacityStart: 0.4;
      --animationDelay: 2s;
    }

    &.brighten {
      animation-name: flash;
    }

    // needs to match nth-of-type(7)
    #outlier {
      transition: margin-top 200ms ease-in-out;
      width: 100%;
      height: 100%;
      background: var(--green);
      --opacityStart: 0.6;
      opacity: 0.6;

      &.animate {
        animation: flash 1.25s linear;
        margin-top: 0 !important;
      }
    }

    @media (max-width: 600px) {
      opacity: 0.4;

      &:nth-of-type(3) {
        background: var(--green);
      }
    }
  }
`;

export default function Brandmark() {
  const animatedBlock = useRef(null);
  const [offset, setOffset] = useState(null);
  const [animate, setAnimate] = useState(false);

  const handleScroll = () =>
    window.scrollY === 0 ? setAnimate(false) : setAnimate(true);

  useEffect(() => {
    if (window.innerWidth > 600) {
      // set initial offset
      const outlierOffset = animatedBlock.current.getBoundingClientRect().top;
      setOffset(outlierOffset);

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <ContainerStyles>
      {[...Array(8)].map((e, i) =>
        i === 2 ? (
          <div key={i}>
            <div
              ref={animatedBlock}
              id="outlier"
              className={animate ? 'animate' : ''}
              style={{ marginTop: `-${offset}px` }}
            />
          </div>
        ) : (
          <div key={i} className={animate ? 'brighten' : ''} />
        )
      )}
    </ContainerStyles>
  );
}
