import React from 'react';
import styled from 'styled-components';

const ContainerStyles = styled.div`
  margin-bottom: 6rem;
  display: inline-block;
  position: relative;

  &::after {
    transition: background 2s linear;
    content: '';
    height: 2px;
    width: 100%;
    display: block;
    position: absolute;
    bottom: calc(1rem - 1px);
    z-index: -1;
  }

  &.agencies::after {
    background: linear-gradient(
      45deg,
      transparent 0,
      transparent 25%,
      var(--green) 25%,
      white 75%,
      transparent 75%
    );
  }

  &.talent::after {
    background: linear-gradient(
      315deg,
      transparent 0,
      transparent 25%,
      var(--green) 25%,
      white 75%,
      transparent 75%
    );
  }

  button {
    width: 12rem;
    padding-bottom: 4rem;

    &::after {
      transition: all 200ms linear;
      content: '';
      height: 2rem;
      width: 2rem;
      border: 2px solid var(--white);
      background: var(--black);
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &.active {
      font-weight: bold;

      &::after {
        border-color: var(--green);
        background: var(--green);
      }
    }
  }
`;

export default function SwiperControls({ activeSection, setActiveSection }) {
  return (
    <ContainerStyles className={activeSection}>
      <button
        className={activeSection === 'agencies' ? 'prev active' : 'prev'}
        type="button"
        onClick={() => setActiveSection('agencies')}
      >
        Agencies
      </button>
      <button
        className={activeSection === 'talent' ? 'next active' : 'next'}
        type="button"
        onClick={() => setActiveSection('talent')}
      >
        Talent
      </button>
    </ContainerStyles>
  );
}
