import React from 'react';
import styled from 'styled-components';
import Form from './Form';

const FormContainerStyles = styled.div`
  transition: all 200ms ease-in;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(var(--rgb), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;

  &.active {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  .content {
    width: 75%;
    max-width: 1020px;
    position: relative;
    background: var(--black);
    color: var(--white);

    &:focus {
      outline: 0;
    }

    .close {
      position: fixed;
      top: 2rem;
      right: 4rem;
      color: var(--black);
      font-weight: bold;

      &.underline::after {
        background: var(--black);
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      height: 100vh;
      overflow: scroll;

      .close {
        color: var(--white);
      }
    }
  }
`;

export default function FormContainer({
  isOverlayShowing,
  updateOverlayState,
}) {
  return (
    <FormContainerStyles className={isOverlayShowing ? 'active' : null}>
      <div className="content">
        <Form updateOverlayState={updateOverlayState} />
        <button
          type="button"
          className="close underline"
          onClick={updateOverlayState}
        >
          Close
        </button>
      </div>
    </FormContainerStyles>
  );
}
