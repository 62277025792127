import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import Modal from 'react-modal';
import SwiperControls from './SwiperControls';
import Form from './Form';
import ModalTrigger from './ModalTrigger';

SwiperCore.use([Navigation, A11y]);
Modal.setAppElement('#___gatsby');

const ContainerStyles = styled.div`
  text-align: center;
  .swiper-slide p {
    width: 75%;
    max-width: 700px;
    margin: 0 auto 6rem;

    @media (max-width: 600px) {
      width: 100%;
      margin: 0 auto 4rem;
    }
  }
`;

const OverlayStyles = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(var(--rgb), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export default function SwiperContainer({ services, updateOverlayState }) {
  const [activeSection, setActiveSection] = useState('agencies');

  return (
    <>
      <ContainerStyles>
        <SwiperControls
          setActiveSection={setActiveSection}
          activeSection={activeSection}
        />
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
          autoHeight
        >
          {services.map((service) => (
            <SwiperSlide key={service.id}>
              <p className="text--size-medium">{service.content}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <ModalTrigger
          updateOverlayState={updateOverlayState}
          activeSection={activeSection}
        />
      </ContainerStyles>
    </>
  );
}
